import { RelativeLayoutContainerUI } from '@lib/layout';
import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';

import * as csp from '@lib/csp/csp';
import { closeIfNot } from '@lib/csp/lib';
import { MaterialIconUI } from '@lib/ui/MaterialIcon';
import { TooltipUI } from '@lib/ui/Tooltip';

import { orderByStartTimeDesc } from '@core/data/sprint.order';
import { unfinished } from '@core/data/task.filter';
import { Deps } from '@core/dep/deps';
import { Sprint } from '@core/entity/sprint';
import { GraphSource } from '@core/storage/graph/graphSource';
import { LocalStore } from '@core/storage/syncer/localStore';
import { StateSyncer } from '@core/storage/syncer/stateSyncer';

import { ConfirmActionsModalComponent } from './Modals/ConfirmActionsModal.component';
import styles from './SprintsHeaderSection.component.module.scss';
import { getEndAt, getSprintName } from './format';

interface Props {
    deps: Deps;
    onCreateSprintClick?: () => void;
}

interface State {
    currentSprint?: Sprint;
    sprints: Sprint[];
    sprintToDelete?: number;
}

export class SprintsHeaderSectionComponent extends Component<Props, State> {
    private readonly localStore: LocalStore;
    private readonly graphSource: GraphSource;
    private readonly stateSyncer: StateSyncer;
    private readonly confirmStartNewSprintModalRef: React.RefObject<ConfirmActionsModalComponent> =
        React.createRef();
    private readonly deleteSprintConfirmTaskRef: React.RefObject<ConfirmActionsModalComponent> =
        React.createRef();
    private stateChangeChan?: csp.PopChannel<boolean | undefined>;

    constructor(props: Props) {
        super(props);
        this.graphSource = props.deps.graphSource;
        this.localStore = props.deps.localStore;
        this.stateSyncer = props.deps.stateSyncer;
        this.state = {
            sprints: [],
        };
    }

    render() {
        const isOnActiveSprint = this.isOnActiveSprint();
        return (
            <div className={styles.SprintHeaderSectionContainer}>
                <TooltipUI
                    message={'Start new sprint'}
                    relativeLayout={this.props.deps.relativeLayout}
                >
                    <div className={styles.CopySection}>
                        <div
                            className={styles.Button}
                            onClick={this.onStartNewSprintClick}
                        >
                            <MaterialIconUI>outbound</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
                <div className={styles.SprintHeaderSection}>
                    <div className={styles.SectionContainer}>
                        <RelativeLayoutContainerUI
                            relativeLayout={this.props.deps.relativeLayout}
                            zOffset={101}
                            referenceOffset={4}
                            renderReferenceElement={({
                                toggleContainer,
                                showFollower,
                            }) => (
                                <TooltipUI
                                    relativeLayout={
                                        this.props.deps.relativeLayout
                                    }
                                    disabled={showFollower}
                                    message={'Current sprint'}
                                >
                                    <div
                                        className={styles.HeaderSection}
                                        onClick={toggleContainer}
                                    >
                                        <div className={styles.Icon}>
                                            <MaterialIconUI>
                                                directions_run
                                            </MaterialIconUI>
                                        </div>
                                        <div className={styles.Name}>
                                            {this.renderSprintName()}
                                        </div>
                                    </div>
                                </TooltipUI>
                            )}
                            renderFollower={({ closeContainer }) => (
                                <div className={styles.Detail}>
                                    <div className={styles.AddSection}>
                                        <div
                                            className={styles.Button}
                                            onClick={this.onCreateSprintClickHandler(
                                                closeContainer,
                                            )}
                                        >
                                            <MaterialIconUI>add</MaterialIconUI>
                                        </div>
                                    </div>
                                    {this.state.sprints.length > 0 && (
                                        <div className={styles.Divider} />
                                    )}
                                    <div className={styles.Sprints}>
                                        {this.state.sprints.map(
                                            (sprint: Sprint) =>
                                                this.renderSprint(
                                                    sprint,
                                                    closeContainer,
                                                ),
                                        )}
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
                <TooltipUI
                    message={'Set active sprint'}
                    relativeLayout={this.props.deps.relativeLayout}
                >
                    <div className={styles.SetActiveSprintSection}>
                        <div
                            className={`${styles.Button} ${classNames({
                                [styles.Active]: isOnActiveSprint,
                                [styles.Disabled]: isOnActiveSprint,
                            })}`}
                            onClick={this.onSetActiveSprintClick}
                        >
                            <MaterialIconUI>star_rate</MaterialIconUI>
                        </div>
                    </div>
                </TooltipUI>
                <ConfirmActionsModalComponent
                    ref={this.confirmStartNewSprintModalRef}
                    message='Do you want to start a new sprint and copy unfinished tasks over?'
                    onConfirm={this.startNewSprint}
                />
                <ConfirmActionsModalComponent
                    ref={this.deleteSprintConfirmTaskRef}
                    message='Do you want to delete this sprint?'
                    onConfirm={this.deleteSprint}
                />
            </div>
        );
    }

    renderSprint = (sprint: Sprint, closeContainer: () => void): ReactNode => {
        return (
            <div
                key={sprint.id}
                className={`${styles.Sprint} ${classNames({
                    [styles.Selected]:
                        sprint.id === this.state.currentSprint?.id,
                })}`}
                onClick={this.onSprintClick(sprint.id, closeContainer)}
            >
                {sprint.id === this.getActiveSprint()?.id && (
                    <div className={styles.Active} />
                )}
                <div>{getSprintName(sprint.startAt)}</div>
                <div
                    className={styles.Delete}
                    onClick={this.onDeleteSprintClick(
                        sprint.id,
                        closeContainer,
                    )}
                >
                    <MaterialIconUI>close</MaterialIconUI>
                </div>
            </div>
        );
    };

    renderSprintName() {
        if (this.state.currentSprint) {
            return getSprintName(this.state.currentSprint.startAt);
        }

        return 'Select Sprint';
    }

    componentDidMount() {
        this.stateChangeChan = this.localStore.subscribeStateChange();
        (async () => {
            while (true) {
                console.log(
                    '[SprintsHeaderSectionComponent] waiting for state changes',
                );
                const hasChanged = await this.stateChangeChan!.pop();
                if (hasChanged === undefined) {
                    // check undefined instead of falsy because
                    // a falsy data could be valid data per channel's concern.
                    return;
                }

                this.updateState();
            }
        })().then();
    }

    componentWillUnmount() {
        closeIfNot(this.stateChangeChan);
    }

    private updateState = () => {
        const currentSprint = this.graphSource.currentSprint();
        let sprints: Sprint[] = this.graphSource.currentTeam()?.sprints || [];
        sprints = orderByStartTimeDesc(sprints);
        this.setState({
            currentSprint,
            sprints,
        });
    };

    private onStartNewSprintClick = () => {
        this.confirmStartNewSprintModalRef.current?.open();
    };

    private isOnActiveSprint = () => {
        const currentTeam = this.graphSource.currentTeam();
        return (
            currentTeam?.activeSprint &&
            this.getActiveSprint()?.id === this.state.currentSprint?.id
        );
    };

    private getActiveSprint = () => {
        const currentTeam = this.graphSource.currentTeam();
        return currentTeam?.activeSprint;
    };

    private onSetActiveSprintClick = () => {
        const currentSprint = this.graphSource.currentSprint();
        const currentTeam = this.graphSource.currentTeam();
        if (this.isOnActiveSprint()) {
            return;
        }

        this.stateSyncer.updateTeamActiveSprint(
            currentTeam!.id,
            currentSprint!.id,
        );
    };

    private canCopyTasksToNextSprint = () => {
        const currentSprint = this.graphSource.currentSprint();
        if (!currentSprint) {
            return false;
        }

        const unfinishedTasks = (currentSprint.tasks || []).filter(unfinished);

        if (!unfinishedTasks.length) {
            return false;
        }

        return true;
    };

    private deleteSprint = async () => {
        if (!this.state.sprintToDelete) {
            return;
        }

        await this.stateSyncer.deleteSprint(this.state.sprintToDelete);
        this.setState({
            sprintToDelete: undefined,
        });
    };

    private startNewSprint = async () => {
        if (!this.canCopyTasksToNextSprint()) {
            alert('can not copy tasks to next sprint');
            return;
        }

        const currentSprint = this.graphSource.currentSprint()!;
        const currentTeam = this.graphSource.currentTeam();
        let nextSprintId = this.getNextSprint(currentSprint)?.id;
        if (!nextSprintId) {
            const startAt = new Date();
            const endAt = getEndAt(startAt, '1week');

            nextSprintId = await this.stateSyncer.createSprint(
                currentTeam!.id,
                {
                    startAt,
                    endAt,
                },
            );
        }

        const unfinishedTasks = (currentSprint.tasks || []).filter(unfinished);
        const unfinishedTaskIds = unfinishedTasks.map((task) => task.id);

        const promises = [];
        if (this.stateSyncer.trySetCurrentSprint(nextSprintId)) {
            promises.push(this.stateSyncer.pullCurrentSprint());
        }

        if (currentTeam?.activeSprint?.id !== nextSprintId) {
            this.stateSyncer.updateTeamActiveSprint(
                currentTeam!.id,
                nextSprintId,
            );
        }

        promises.push(
            this.stateSyncer.copyTasksToSprint(nextSprintId, unfinishedTaskIds),
        );
        await Promise.all(promises);
    };

    private getNextSprint(currentSprint: Sprint) {
        let sprints = [...this.state.sprints];
        sprints = orderByStartTimeDesc(sprints);
        const currentIndex = sprints.findIndex(
            (sprint) => sprint.id === currentSprint.id,
        );

        if (currentIndex === 0) {
            return null;
        }

        return sprints[currentIndex - 1];
    }

    private onCreateSprintClickHandler = (closeContainer: () => void) => {
        return () => {
            closeContainer();
            this.props.onCreateSprintClick?.call(null);
        };
    };

    private onSprintClick = (sprintId: number, closeContainer: () => void) => {
        return async () => {
            if (this.stateSyncer.trySetCurrentSprint(sprintId)) {
                this.stateSyncer.pullCurrentSprint();
            }

            closeContainer();
        };
    };

    private onDeleteSprintClick = (
        sprintToDelete: number,
        closeContainer: () => void,
    ) => {
        return () => {
            this.setState({
                sprintToDelete,
            });
            this.deleteSprintConfirmTaskRef.current?.open();
        };
    };
}
